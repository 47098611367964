import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {
	LineSeparator,
	PageContent,
	PageContentWrapper,
	PageHero,
	PageHeroTitle,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import { colors } from "../assets/styles/vars";
import ModulesList from "../components/Common/ModulesList";
import { linksInteresModule } from "../data/global-data";
import { CustomLink } from "../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";

const NotFoundPage = () => (
	<Layout>
		<Seo title="404: Not Found" />
		<section>
			<PageHero bgImg="/results/bg/main-banner.jpg">
				<PageHeroTitle>
					<div className="titleContent">
						<h1>404</h1>
						<h3>¡Fuera de pista!</h3>
					</div>
				</PageHeroTitle>
			</PageHero>
			<PageContentWrapper bgColor={colors.grayF2}>
				<PageContent width="1050px">
					<PageTwoColWrapper>
						<div className="pageCol leftCol">
							<h4>
								Nos pasa a todos, explorando la montaña es normal
								perderse 🙃.
							</h4>
							<p>Vuelve a subirte al remonte e inténtalo denuevo.</p>

							<LineSeparator />
							<CustomLink to="/" className="withIcon">
								<p>Volver a la home</p>
								<ReactSVG
									src="/img/icons/icon-home.svg"
									wrapper="span"
									className="icon"
								/>
							</CustomLink>
						</div>

						<div className="pageCol rightCol">
							<ModulesList items={linksInteresModule} />
						</div>
					</PageTwoColWrapper>
				</PageContent>
			</PageContentWrapper>
		</section>
	</Layout>
);

export default NotFoundPage;
