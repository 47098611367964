import React from "react";
import { ModulesListWrapper, ListItem } from "./styled";
import { StyledTooltip } from "../../../assets/styles/pageCommon";
import { ReactSVG } from "react-svg";
import { Link } from "gatsby";

const ModulesList = ({ items }) => {
	const renderItemContent = (item, index) => {
		const {
			moduleUrl,
			icon,
			title,
			text,
			tooltipIcon,
			tooltipIconColor,
			tooltipText,
			links,
		} = item;
		return (
			<>
				{tooltipIcon && tooltipText && (
					<div className="tooltipWrapper">
						<ReactSVG
							src={tooltipIcon}
							wrapper="span"
							className={`icon-tooltip ${tooltipIconColor}`}
							data-tip="React-tooltip"
							data-for={`module-tooltip-${index}`}
						/>
						<StyledTooltip
							id={`module-tooltip-${index}`}
							place="bottom"
							type="light"
							effect="solid"
						>
							<p dangerouslySetInnerHTML={{ __html: tooltipText }} />
						</StyledTooltip>
					</div>
				)}
				<ReactSVG src={icon} wrapper="span" className="icon list-icon" />
				{title && <h4 dangerouslySetInnerHTML={{ __html: title }} />}
				{text && <p dangerouslySetInnerHTML={{ __html: text }} />}
				{!moduleUrl &&
					links &&
					links?.map((item, index) => (
						<Link key={index} to={item.linkUrl}>
							{item.linkText}
						</Link>
					))}
			</>
		);
	};

	return (
		<ModulesListWrapper>
			<div className="listRow">
				{items?.map((item, index) => {
					return (
						<ListItem key={index}>
							{item.moduleUrl ? (
								<Link className="inner" to={item.moduleUrl}>
									{renderItemContent(item, index)}
								</Link>
							) : (
								<div className="inner">
									{renderItemContent(item, index)}
								</div>
							)}
						</ListItem>
					);
				})}
			</div>
		</ModulesListWrapper>
	);
};

export default ModulesList;
